import { defineStore } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import {
  AconexUserProjectsResponsePayload,
  ProcoreUserProjectsResponsePayload,
  ProcoreProjectsAlbumsPayload,
  Connector,
  ConnectorDetail,
  AutodeskUserHubsResponsePayload,
  AutodeskProjectsResponsePayload,
  AutodeskFolderResponsePayload,
  AnalyticsEvent,
} from "@evercam/shared/types"
import { useNuxtApp } from "#app"
import { AxiosError } from "axios"
import axios from "axios"

type ConnectorState = {
  connectorPromptDialog: boolean
  selectedConnector: Connector
  isProcoreConnected: boolean
  isAconexConnected: boolean
  isExpiredConnectorToken: boolean
  isAutodeskConnected: boolean
  isVoyageControlConnected: boolean
  isProcoreAlbumFetchFailed: boolean
  aconexConnectorDetails: ConnectorDetail | null
  procoreConnectorDetails: ConnectorDetail | null
  autodeskConnectorDetails: ConnectorDetail | null
  voyageControlConnectorDetails: ConnectorDetail | null
  autodeskViewerConnectorDetails: ConnectorDetail | null
  procoreUserProjects: ProcoreUserProjectsResponsePayload
  procoreProjectAlbums: ProcoreProjectsAlbumsPayload
  aconexUserProjects: AconexUserProjectsResponsePayload
  autodeskHubs: AutodeskUserHubsResponsePayload
  autodeskProjects: AutodeskProjectsResponsePayload
  autodeskFolders: Array<AutodeskFolderResponsePayload>
  uploadImageData: string
}

export const useConnectorStore = defineStore("connector", {
  state: (): ConnectorState => ({
    connectorPromptDialog: false,
    selectedConnector: null,
    isProcoreConnected: false,
    isAconexConnected: false,
    isAutodeskConnected: false,
    isExpiredConnectorToken: false,
    isVoyageControlConnected: false,
    isProcoreAlbumFetchFailed: false,
    aconexConnectorDetails: null,
    procoreConnectorDetails: null,
    autodeskConnectorDetails: null,
    autodeskViewerConnectorDetails: null,
    voyageControlConnectorDetails: null,
    procoreUserProjects: [],
    procoreProjectAlbums: [],
    aconexUserProjects: [],
    autodeskHubs: [],
    autodeskProjects: [],
    autodeskFolders: [],
    uploadImageData: "",
  }),
  actions: {
    async fetchAutodeskHubs() {
      this.autodeskHubs = []
      if (!this.isAutodeskConnected) {
        return
      }
      try {
        this.autodeskHubs = await EvercamApi.autodesk.getUserHubs()
      } catch (error) {
        this.handleConnectorRequestError(
          error as AxiosError,
          Connector.Autodesk
        )
      }
    },
    async fetchAutodeskProjects(hubId) {
      this.autodeskProjects = []
      if (!this.isAutodeskConnected) {
        return
      }

      this.autodeskProjects = await EvercamApi.autodesk.getProjects(hubId)
    },
    async fetchAutodeskFolders(hubId: string, projectId: string) {
      this.autodeskFolders = []
      if (!this.isAutodeskConnected) {
        return
      }

      this.autodeskFolders = await EvercamApi.autodesk.getFolders(
        hubId,
        projectId
      )
    },

    async fetchAutodekFolder(projectId: string, folderId: string) {
      if (!this.isAutodeskConnected) {
        return
      }

      return await EvercamApi.autodesk.getFolder(projectId, folderId)
    },
    async fetchAutodeskSubfolder(
      item: AutodeskFolderResponsePayload,
      projectId: string,
      getDerivatives: Boolean = false
    ) {
      const children = await EvercamApi.autodesk.getSubFolders(
        projectId,
        item?.value,
        getDerivatives
      )
      if (children?.length) {
        children.forEach(
          (child) =>
            (child.parentName = (item?.parentName ?? "") + item.text + "/")
        )
        item.children = children
      } else {
        item.children = undefined
      }

      return item
    },
    async getUserCompanies() {
      if (!this.isProcoreConnected) {
        this.procoreUserProjects = []

        return
      }
      try {
        const userProcoreCompanies = await EvercamApi.procore.getUserCompanies()
        this.getProcoreUserProjects(userProcoreCompanies)
      } catch (error) {
        this.handleConnectorRequestError(error as AxiosError, Connector.Procore)
      }
    },
    handleConnectorRequestError(error: AxiosError, connectorType: Connector) {
      if (
        error.response?.data?.code === "INVALID_TOKEN" ||
        error.response?.data?.message ===
          `Expired ${connectorType.toLowerCase()} token`
      ) {
        this.openConnectorPromptDialog(connectorType)
        this.isExpiredConnectorToken = true
        this.getConnectors()
      }
    },
    async getProcoreUserProjects(userProcoreCompanies) {
      this.procoreUserProjects = []
      for (const company of userProcoreCompanies) {
        try {
          const projects = await EvercamApi.procore.getUserProjects({
            companyId: company.id,
          })
          this.procoreUserProjects = [...this.procoreUserProjects, ...projects]
        } catch (error) {
          console.error(error)
        }
      }
    },
    async getProcoreProjectAlbums(payload) {
      if (!this.isProcoreConnected) {
        this.procoreProjectAlbums = []

        return
      }
      try {
        this.procoreProjectAlbums = await EvercamApi.procore.getProjectAlbums(
          payload
        )
        this.isProcoreAlbumFetchFailed = false
      } catch (error) {
        this.procoreProjectAlbums = []
        this.isProcoreAlbumFetchFailed = true
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.connectors.procore_fetch_project_albums_failed"
          ) as string,
        })
      }
    },
    async getAconexUserProjects() {
      if (!this.isAconexConnected) {
        this.aconexUserProjects = []

        return
      }
      try {
        this.aconexUserProjects = await EvercamApi.aconex.getUserProjects()
      } catch (error) {
        this.handleConnectorRequestError(error as AxiosError, Connector.Aconex)
      }
    },
    async getConnectors() {
      const providers = await EvercamApi.providers.getProviders()
      const aconex = providers.find(
        (item) => item.provider === Connector.Aconex
      )
      const procore = providers.find(
        (item) => item.provider === Connector.Procore
      )
      const autodesk = providers.find(
        (item) => item.provider === Connector.Autodesk
      )
      const voyageControl = providers.find(
        (item) => item.provider === Connector.VoyageControl
      )
      this.isAconexConnected = !!aconex
      this.aconexConnectorDetails = aconex
      this.isProcoreConnected = !!procore
      this.procoreConnectorDetails = procore
      this.isAutodeskConnected = !!autodesk
      this.autodeskConnectorDetails = autodesk
      this.isVoyageControlConnected = !!voyageControl
      this.voyageControlConnectorDetails = voyageControl
      this.getAconexUserProjects()
      this.getUserCompanies()
      this.fetchAutodeskHubs()
    },
    async revokeProcoreConnection() {
      try {
        await EvercamApi.procore.sendRevoke()
        await this.getConnectors()
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.connectors.procore_remove_success"
          ) as string
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.connectors.revoke_procore_failed"
          ) as string,
          error,
        })
      }
    },
    async revokeAconexConnection() {
      try {
        await EvercamApi.aconex.revoke()
        await this.getConnectors()
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.connectors.aconex_remove_success"
          ) as string
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.connectors.aconex_remove_error"
          ) as string,
          error,
        })
      }
    },
    async revokeAutodeskConnection() {
      try {
        await EvercamApi.autodesk.sendRevoke()
        await this.getConnectors()
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.connectors.autodesk_remove_success"
          ) as string
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.connectors.revoke_autodesk_failed"
          ) as string,
          error,
        })
      }
    },
    async revokeVoyageControlConnection() {
      try {
        await EvercamApi.voyageControl.sendRevoke()
        await this.getConnectors()
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.connectors.voyage_control_remove_success"
          ) as string
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.connectors.revoke_voyage_control_failed"
          ) as string,
          error,
        })
      }
    },
    async setForgeModelId(projectId, item) {
      const nuxt = useNuxtApp()
      try {
        await axios.patch(
          `${nuxt.$config.public.firebaseDbLink}data/projects/forge/${projectId}.json`,
          JSON.stringify({
            urn: item.value,
          })
        )
        nuxt.nuxt2Context.$notifications.success(
          nuxt.vue2App.$i18n.t(
            "content.connectors.autodesk_model_change_success"
          ) as string
        )
      } catch (error) {
        nuxt.nuxt2Context.$notifications.error({
          text: nuxt.vue2App.$i18n.t(
            "content.connectors.autodesk_model_change_error"
          ) as string,
          error,
        })
      }
    },
    handle401Error(error: AxiosError) {
      const url = error.config?.url

      if (url.includes(Connector.Procore)) {
        this.isProcoreConnected = false
      } else if (url.includes(Connector.Aconex)) {
        this.isAconexConnected = false
      } else if (url.includes(Connector.Autodesk)) {
        this.isAutodeskConnected = false
      }
    },
    openConnectorPromptDialog(connector) {
      this.connectorPromptDialog = true
      this.selectedConnector = connector
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.SettingsConnectorsToggleConnectorPromptDialog,
        { visible: true, connector: this.selectedConnector }
      )
    },
    closeConnectorPomptDialog() {
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.SettingsConnectorsToggleConnectorPromptDialog,
        { visible: false, connector: this.selectedConnector }
      )
      this.connectorPromptDialog = false
      this.selectedConnector = null
    },
  },
  getters: {
    isConnectionError: () => (error: AxiosError) => {
      const url = error.config?.url

      return Object.values(Connector).some((connector) =>
        url.includes(connector)
      )
    },
  },
})
